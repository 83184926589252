<template>
  <!-- begin::page-container-->
  <main class="page-container" id="sme-edu-apply">
    <!-- begin::edu-banner -->
    <div class="edu-banner">
      <div class="banner-content">
        <div class="page-component">
          <h2 v-if="isMobile" class="banner-title" style="font-size: 1.54rem">22년 소상공인 인플루언서 교육 <br/>기본・심화 정규코스</h2>
          <h2 v-else class="banner-title">22년 소상공인 인플루언서 교육 <br/>기본・심화 정규코스</h2>
        </div>
      </div>
      <div class="banner-image">
        <img v-if="isMobile" src="../../../assets/mobile/img/edu/bg_intro_02.png" alt="기본심화 정규코스 강좌 배너" />
        <img v-else src="../../../assets/img/edu/bg_intro_02.png" alt="기본심화 정규코스 강좌 배너" />
      </div>
    </div>
    <!-- end::edu-banner -->
    <!-- begin::edu-contents -->
    <div class="edu-contents">
      <!-- edu-section:edu-section-text -->
      <section class="edu-section edu-section-text" style="padding-top: 60px;">
        <div class="section-container page-component" style="display: flex;flex-direction: column;align-items: center;">
          <header class="section-header">
            <h3 class="title">앗, 아직 사업신청을 안하셨거나,<br/>신청하셨어도, 선정이 안되셨네요.<br/>사업은 언제든 재신청 가능합니다.</h3>
          </header>
<!--          <div class="text-container">-->
<!--            <p class="text">정규교육 신청하기 : (기본과정) <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="text-link">https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3</a></p>-->
<!--            <p class="left-text" style="text-indent: 100px">(심화과정) <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="text-link">https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3</a></p>-->
<!--          </div>-->
<!--          <div style="display: flex;flex-direction: row;justify-content: space-around;width: 680px;margin-bottom: 60px">-->
<!--            <div class="btn-container">-->
<!--              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="btn btn-primary" style="width: 250px">-->
<!--                <span class="text">기본과정 교육신청</span>-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="btn-container">-->
<!--              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="btn btn-primary" style="width: 250px">-->
<!--                <span class="text">심화과정 교육신청</span>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
          <div v-if="isMobile" style="display: flex;flex-direction: row;justify-content: space-around;min-width: 350px;">
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="btn btn-primary">
                <span class="text">기본과정 교육신청</span>
              </a>
            </div>
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="btn btn-primary">
                <span class="text">심화과정 교육신청</span>
              </a>
            </div>
          </div>
          <div v-else style="display: flex;flex-direction: row;justify-content: space-around;min-width: 350px;width: 680px;margin-bottom: 30px">
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="btn btn-primary" style="width: 250px">
                <span class="text">기본과정 교육신청</span>
              </a>
            </div>
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="btn btn-primary" style="width: 250px">
                <span class="text">심화과정 교육신청</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <!-- //edu-section:edu-section-banner -->
      <!-- //edu-section:edu-section-banner -->
      <!-- edu-section:edu-section-login -->
      <!-- [YYY:220323] 삭제 <section class="edu-section edu-section-login">
          <div class="section-container page-component">
              <header class="section-header">
                  <h3 class="title">교육플랫폼 로그인</h3>
              </header>
              <form action="">
                  <div class="login-container">
                      <div class="form-row">
                          <input type="text" class="form-control" placeholder="아이디" />
                      </div>
                      <div class="form-row">
                          <input type="password" class="form-control" placeholder="비밀번호" />
                      </div>
                      <button class="btn btn-primary btn-login">
                          <span class="text">로그인</span>
                      </button>
                  </div>
              </form>
          </div>
      </section> -->
      <!-- //edu-section:edu-section-banner -->

    </div>
    <!-- end::edu-contents -->
  </main>
  <!-- end::page-container(#기본심화 정규코스) -->
</template>

<script>
import {ACT_GET_EDU_COURSE_LIST, ACT_POST_EDU_SSO} from '../../../store/_act_consts';
import {getItems, getPaging, isSuccess, lengthCheck, setPaging} from '../../../assets/js/utils';
import {mapGetters} from 'vuex';
import store from '../../../store';
import {MUT_SHOW_ALERT} from '../../../store/_mut_consts';
import router from '../../../router';
import LoginCheckButton from '../../../components/common/LoginCheckButton';

export default {
  name: 'Education',
  components: {
    LoginCheckButton
  },
  data:()=>({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    items: [],
    courseTypeDcds:[
      {courseTypeDcd: '1012001', courseType:'이러닝'},
      {courseTypeDcd: '1012002', courseType:'전화어학'},
      {courseTypeDcd: '1012003', courseType:'집합교육'},
      {courseTypeDcd: '1012004', courseType:'북러닝'},
      {courseTypeDcd: '1012005', courseType:'소셜'},
      {courseTypeDcd: '1012006', courseType:'마이크로러닝'},
      {courseTypeDcd: '1012007', courseType:'블렌디드'},
    ]
  }),
  computed:{
    ...mapGetters('auth', ['isAuth']),
    ...mapGetters('common', ['isMobile']),
    refineItems(){
      return this.items.map(x => ({
        courseId: x.courseId,
        courseNm: x.courseNm,
        courseTypeDcd: x.courseTypeDcd,
        courseType: ((item) => {
          const courseFilter = this.courseTypeDcds.filter(f => item.courseTypeDcd === f.courseTypeDcd);

          if(courseFilter.length > 0) return courseFilter[0].courseType
          return '';
        })(x),
        categoryNm3: x.categoryNm3,
        courseImg: x.courseImg,
        previewYn: x.previewYn
      }));
    }
  },
  created() {
    this.getCourse(true);
  },
  methods:{
    getCourse(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }

      this.$store.dispatch(`education/${ACT_GET_EDU_COURSE_LIST}`,{
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          this.hasMore = false;
        }
      }).catch(e=>{
        this.hasMore = false;
        console.error(e);
      })
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getCourse();
    },
    clickPreview(courseId){
      window.open(`https://kodma.getsmart.co.kr/course/${courseId}/player/preview`, "새창", "width=800, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=yes, encrypted-media");
    },
    goEducation(){
      this.$store.dispatch(`education/${ACT_POST_EDU_SSO}`).then(res=>{
        if(isSuccess(res)){
          if(res.encId){
            location.href = `https://kodma.getsmart.co.kr/client/sso?memberId=${encodeURIComponent(res.encId)}`;
          }
        }
      });
    }
  }
};
</script>

